/** @jsx h */

import type { ComponentChildren, VNode } from 'preact';

import { clsx } from 'clsx';
import { h } from 'preact';
import { useEffect } from 'preact/hooks';

import type { Values } from '../../../types';

import { trackBuyerEvent } from '../../../biz';
import { Z_INDEX_MAX } from '../../../constants';
import { assertUnreachable, MODAL_TYPE, run, track } from '../../../lib';
import { Dialog } from '../../dialog';
import { useConsentPopupContext } from '../context';

import { CloseButton } from './close-button';

import { BUYER_EVENT } from '@onetext/api';

export const POPUP_LAYOUT = {
    FULLSCREEN: 'fullscreen',
    MODAL:      'modal'
} as const;

type ConsentPopupProps = {
    children ?: ComponentChildren,
    layout ?: Values<typeof POPUP_LAYOUT>,
    background ?: JSX.Element,
    overlayClassName ?: string,
    modalClassName ?: string,
};

export const ConsentPopup = ({
    children,
    layout = POPUP_LAYOUT.MODAL,
    background,
    overlayClassName,
    modalClassName
} : ConsentPopupProps) : VNode | null => {
    const {
        accountToken,
        activePageID,
        isPopupOpen,
        brandConfig,
        suppressPopup,
        popup
    } = useConsentPopupContext();

    useEffect(() => {
        if (activePageID) {
            const EVENT_NAME = 'consent_popup_page_render';

            track(EVENT_NAME, {
                activePageID
            });

            trackBuyerEvent(
                {
                    buyerEvent: BUYER_EVENT.POPUP_PAGE_RENDER,
                    payload:    {
                        popupID:          popup.token,
                        activePageNumber: activePageID
                    }
                }
            );
        }
    }, [ activePageID ]);

    useEffect(() => {
        track('consent_popup_init', {
            accountToken
        });

        if (suppressPopup.suppressed) {
            track('consent_popup_suppress', {
                reason: suppressPopup.reason
            });
        } else {
            track('consent_popup_render', {
                accountToken
            });
        }
    }, [ suppressPopup.suppressed ]);

    if (!isPopupOpen || suppressPopup.suppressed) {
        return null;
    }

    const fontFamily = brandConfig?.font?.family?.primary ?? 'Helvetica Neue';
    const textColor = brandConfig?.colors?.text ?? '#000000';
    const backgroundColor = brandConfig?.colors?.background ?? '#ffffff';

    return (
        <Dialog>
            <div
                id={ 'ot-popup-overlay' }
                className={
                    clsx(
                        'w-full h-full',
                        'top-0 left-0 fixed',
                        'fade-in-up',
                        overlayClassName
                    )
                }
                style={
                    {
                        zIndex: Z_INDEX_MAX
                    }
                }>
                <div
                    id={ 'ot-popup-modal' }
                    className={
                        clsx(
                            run(() => {
                                switch (layout) {
                                    case POPUP_LAYOUT.FULLSCREEN:
                                        return 'w-full h-full top-0 left-0 fixed';
                                    case POPUP_LAYOUT.MODAL:
                                        return clsx(
                                            'top-[50%] left-[50%] fixed',
                                            '-translate-x-1/2 -translate-y-1/2',
                                            'w-full md:w-auto',
                                            'md:min-w-[40vw] min-h-[40vh]'
                                        );
                                    default:
                                        throw assertUnreachable(layout);
                                }
                            }),
                            'flex flex-col justify-center items-center',
                            'text-slate-900',
                            modalClassName
                        )
                    }
                    style={
                        {
                            fontFamily: `'${ fontFamily }', sans-serif`,
                            color:      textColor,
                            backgroundColor
                        }
                    }
                >
                    { background }
                    <div className={ 'relative w-full h-full z-10' }>
                        { children }
                    </div>
                    <CloseButton type={ MODAL_TYPE.POPUP } />
                </div>
            </div>
        </Dialog>
    );
};
