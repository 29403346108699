/** @jsx h */

import type { VNode } from 'preact';

import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';

import type { ExternalID } from '../../../types';

import { getLivePopup } from '../../../api';
import { getBrandSDKPopupID, promiseTry } from '../../../lib';

import type { GetPopupAPIResponse } from '@onetext/api';

type PopupComponentProps = {
    popup : GetPopupAPIResponse,
};

type PopupComponent<ChildType extends JSX.Element | VNode> = (
    props : PopupComponentProps
) => ChildType | null;

export type ConsentMultiPopupThemes<ChildType extends JSX.Element | VNode> = {
    [ key : ExternalID ] : PopupComponent<ChildType>,
};

type ConsentMultiPopupProps<ChildType extends JSX.Element | VNode> = {
    script : HTMLScriptElement,
    themes : ConsentMultiPopupThemes<ChildType>,
};

export const ConsentMultiPopup = <ChildType extends JSX.Element | VNode>({
    script,
    themes
} : ConsentMultiPopupProps<ChildType>) : VNode | null => {
    const [ popup, setPopup ] = useState<GetPopupAPIResponse | undefined>();
    const [ isLoading, setIsLoading ] = useState(true);

    useEffect(() => {
        const externalIDOverride = getBrandSDKPopupID(script);
        const finalExternalIDOverride = externalIDOverride && externalIDOverride in themes
            ? externalIDOverride
            : undefined;

        void promiseTry(async () => {
            const popupData = await getLivePopup({ externalIDOverride: finalExternalIDOverride });
            setPopup(popupData);
        }).then(() => setIsLoading(false));
    }, [ script, themes ]);

    const PopupComponent = popup?.externalID && popup.externalID in themes
        ? themes[popup.externalID] as PopupComponent<VNode>
        : undefined;

    if (isLoading || !popup || !PopupComponent) {
        return null;
    }

    return (
        <PopupComponent popup={ popup } />
    );
};
