import { callOneTextAPI } from '../api';
import { eventEmitter } from '../lib';

import { getStoredAccountToken, getStoredBrowserIdentifier, getStoredBrowserSessionIdentifier } from './customer';

import type { BUYER_EVENT, Email, PhoneNumber, PostEventTrackAPIRequest } from '@onetext/api';
import { HTTP_METHOD } from '@onetext/api';

type EventsPayload = PostEventTrackAPIRequest['events'];

type CommonFields = {
    customerAccountToken ?: string,
    customerBrowserIdentifier : string,
    customerBrowserSessionIdentifier : string,
};

type BuyerEventPayload<T> = T extends { buyerEvent : BUYER_EVENT, payload : infer P }
    ? Omit<P, keyof CommonFields>
    : never;

export const trackBuyerEvent = <T extends EventsPayload[0]>(
    event : {
        buyerEvent : T['buyerEvent'],
        payload : BuyerEventPayload<T>,
    }
) : void => {
    void callOneTextAPI({
        method: HTTP_METHOD.POST,
        path:   '/event/track',
        body:   {
            events: [ {
                buyerEvent: event.buyerEvent,
                payload:    {
                    ...event.payload,
                    customerAccountToken:             getStoredAccountToken(),
                    customerBrowserIdentifier:        getStoredBrowserIdentifier(),
                    customerBrowserSessionIdentifier: getStoredBrowserSessionIdentifier()
                }
            } ]
        }
    });
};

export const onPopupEmailSubmit = eventEmitter<{
    email : Email,
}>();

export const onPopupPhoneSubmit = eventEmitter<{
    phone : PhoneNumber,
}>();

export const events = {
    popup: {
        email: {
            submit: onPopupEmailSubmit
        },
        phone: {
            submit: onPopupPhoneSubmit
        }
    }
};
